import { NgModule, InjectionToken } from '@angular/core';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export class AppConfig {
    products: string[] = [];
    pages: Page[] = [];
}

interface Page {
    nosession: string[];
    session: string[];
}

export const APP_DI_CONFIG: AppConfig = {
    products: [
        'midc',
        'bc',
        'pntd',
        'vrf',
        'os'
    ],
    pages: [
        {
            nosession: [
                'login',
                'registro',
                'recovery',
                'oneshot',
                'error',
                'terminos-condiciones'
            ],
            session: [
                'validacion',
                'validacion,codigo',
                'validacion,evidente',
                'private',
                'private,perfil',
                'error',
                'oneshot,payments',
                'oneshot,confirmacion'
            ]
        },
        {
            nosession: [
                'login',
                'registro',
                'recovery',
                'error',
                'terminos-condiciones'
            ],
            session:  [
                'private',
                'private,perfil',
                'error'
            ]
        },
        {
            nosession: [
                'login',
                'registro',
                'recovery',
                'error',
                'terminos-condiciones'
            ],
            session:  [
                'private',
                'private,perfil',
                'error'
            ]
        },
        {
            nosession: [
                'login',
                'registro',
                'recovery',
                'error',
                'terminos-condiciones'
            ],
            session:  [
                'private',
                'private,perfil',
                'error'
            ]
        },
        {
            nosession: [
                'login',
                'registro',
                'recovery',
                'error',
                'oneshot',
                'terminos-condiciones'
            ],
            session:  [
                // 'private',
                // 'private,perfil',
                'error',
                'oneshot,payments',
                'oneshot,confirmacion'
            ]
        }
    ]
};

@NgModule({
  providers: [{
    provide: APP_CONFIG,
    useValue: APP_DI_CONFIG
  }]
})
export class AppConfigModule { }
