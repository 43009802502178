import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
declare var dataLayer: any;

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {

  constructor(private activatedRoute: ActivatedRoute) { }

  /**
   * EVENTO GA4 user
   */
  dl_userId(){
    dataLayer.push({
      'event':'ga_event',
      'name_event':'user',
      'user_id': null, 
      'user_tye': 'anonimo'
    });
  }

  /**
   * EVENTO GA4 de 1 parámetro
   * @param name_event: Nombre de evento.
   */
  dl_eventoGA4_general( name_event:string ){
    dataLayer.push({
      'event': 'ga_event',
      'name_event' : name_event
    });
  }

  /**
   * EVENTO GA4 
   * Esta función sirve para el envió de diferentes data layers que comparten los mismos parámetros
   * En la última actualización, se agrego el parametro "funn" únicamente al data layer de login y es 
   * por este motivo se realiza una condición con el if y el else.
   * @param name_event: nombre del evento data layer.
   * @param method: parametro url (midc, bc, pntd, etc).
   * @param funn: utilizado para enviar el funnel de ser este el caso.
   */
  dl_eventoGA4_login( name_event:string, method:string, funn: string = 'default' ){

    if ( name_event == 'login' ){
      this.activatedRoute.queryParams.subscribe( params => {
        if (params['funn'] != undefined){
          funn = params['funn'];
        }
      });
      dataLayer.push({
        'event': 'ga_event',
        'name_event': 'login',
        'method': method,
        'funnel': funn
      });

    } else {
      dataLayer.push({
        'event': 'ga_event',
        'name_event' : name_event,
        'method': method
      });
    }
  }

  /**
   * EVENTO GA4 - Parámetro: menu_option
   */
  dl_eventoGA4_menu( name_event: string, texto: string ){
    dataLayer.push({
      'event': 'ga_event',
      'name_event': name_event,
      'menu_option': texto,
    });
  }

  /**
   * EVENTO GA4 button_name
   * @param method: parametro url 
   */
  dl_eventoGA4_button_name( name_event:string, method :string, button_name: string ){
    dataLayer.push({
      'event': 'ga_event',
      'name_event' : name_event,
      'method': method,
      'button_name': button_name
    });
  }
}
