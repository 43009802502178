<div class="modal fade modal" 
id="modal" tabindex="-1" role="dialog" aria-hidden="true" 
data-backdrop="static"
[ngClass]="{'modal-error': content.typeModal === 'modal-error', 'modal-confirm': content.typeModal === 'modal-confirm'}">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content p-2">
        <div class="modal-body">
          <button type="button" class="close mdc-close" data-dismiss="modal" aria-label="Close" *ngIf="content.closeButton">
            <span aria-hidden="true" class="text-white">&times;</span>
          </button>
          <div class="row d-flex  h-100">
            <div class="d-block d-lg-none offset-3 col-6 text-center" *ngIf="content.imgLateral">
              <img src="{{content.imgLateral}}" class="icon" alt="Validacion exitosa">
            </div>
            <div class="col-12 col-lg-6 my-2 p-0 p-lg-5 text-center justify-content-center align-self-center">
              <div class="title my-2">
                  {{ content.titulo }}
              </div>
              <div class="txt p-2">
                <p  [innerHTML]="content.textoHTML">
                </p>
              </div>
              <br>

              <button 
              *ngIf="(content.typeModal === 'modal-confirm') && (content.textBoton) && (!content.enlaceBoton)" 
              class=" btn-lg btn text-center d-block m-auto btn-primary mt-5" 
              data-dismiss="modal">{{ content.textBoton }} <i class="arrow right"></i></button>

              <button 
              *ngIf="(content.typeModal === 'modal-error') && (content.textBoton) && (!content.enlaceBoton)" 
              class="btn-lg btn text-center d-block m-auto btn-error mt-5 text-white" 
              data-dismiss="modal">{{ content.textBoton }} <i class="arrow right"></i></button>

              <a 
              *ngIf="(content.typeModal === 'modal-error') && (content.enlaceBoton)" 
              href="{{content.enlaceBoton}}"
              class="btn-lg btn text-center d-block m-auto btn-error mt-5 text-white">
                  {{ content.textBoton }}
                  <i class="arrow right"></i>
              </a>

              <a 
              *ngIf="(content.typeModal === 'modal-confirm') && (content.enlaceBoton)" 
              href="{{content.enlaceBoton}}"
              class="btn-lg btn text-center d-block m-auto btn-primary mt-5 text-white">
                  {{ content.textBoton }}
                  <i class="arrow right"></i>
              </a>

              <a data-dismiss="modal" *ngIf="(content.typeModal === 'modal-pass') && (content.enlaceBoton)" class="btn-lg btn text-center d-block m-auto btn-primary mt-5 text-white" (click)="sesion.navigateToRoute(contenido.info.login?.urlremember)">
                {{ content.textBoton }}
                <i class="arrow right"></i>
              </a>
                
              <a 
              *ngIf="content.enlace2texto" (click)="content.close()"
              class="text-center d-block m-auto pt-2 link" style="cursor: pointer;">
                  {{ content.enlace2texto }}
              </a>
            </div>
            <div class="d-none d-lg-block col-lg-6 p-5 justify-content-center align-self-center text-right" *ngIf="content.imgLateral">
              <img src="{{content.imgLateral}}" class="icon" alt="Validacion exitosa">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>