import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { SesionService } from 'src/app/services/sesion.service';
import { ProductService } from 'src/app/services/product.service';

declare var $: any;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  constructor( public content: ModalService, public sesion: SesionService, public contenido: ProductService) {
  }

  ngOnInit() {
  }
}
