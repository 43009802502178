import { Injectable } from '@angular/core';

declare var $: any;
declare var bodymovin: any;

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  titulo: string;
  texto: string;

  constructor() {
    this.titulo = 'Por favor espera un momento ...';
    this.texto = 'Estamos validando tu identidad';
  }

  close() {
    this.titulo = '';
    this.texto = '';
    $('#loading').modal('hide');
  }

  open( title:any, text:any ) {
    this.titulo = title;
    this.texto = text;
    if ($('#loadingAnimation').get(0)) {
      $('#loadingAnimation').html('');
      const animation = bodymovin.loadAnimation({
        container: document.getElementById('loadingAnimation'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: 'assets/images/animations/loading_clock.json',
        crossOrigin: null
      });
      setTimeout(() => {
        $('#loading').modal({backdrop: 'static', keyboard: false});
      }, 500);
    }
  }
}
