import { Injectable } from '@angular/core';
import { ContentType } from '../interfaces/content.interface';
import { BrokerService } from './broker.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  info = {} as ContentType;
  producto: any = null;

  constructor(private broker: BrokerService, private sanitizer: DomSanitizer, private router: Router) {
  }

  obtenerContenido() {
    this.broker.obtener_contenido( this.producto ).subscribe( ( data: any ) => {
      this.info = data;
    },
    (error) => {
      this.router.navigate(['/login'], {queryParams: {product: 'midc'}});
    }
    );
  }

  logoURL( url: any ) {
    return this.sanitizer.bypassSecurityTrustUrl( url );
  }
}
