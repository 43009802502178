import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare var googletag: any;

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  public utmParams: Params = {};
  public haveUtm: boolean = false;

  constructor(public route: ActivatedRoute, public router: Router) { }

  /**
   * Esta función valida si el slot existe
   *  - Si existe, lo despliega de nuevo.
   *  - Si no existe, lo crea, se configura y se renderiza.
   * Adicionalmente, tiene un setTimeout para garantizar que cargue completamente en caso de entrar en un ngIf o similar.
   * @param slot Equivale al ID(slot) que hace referencia al configurado en el script de Google Ads.
   * @param id Este id hace referencia al ID del div donde se renderizará el banner.
   */
  addPubliById(slot: string, id: string) {
    setTimeout(() => {
      googletag.cmd.push(() => {
        var slotAdd = googletag.pubads().getSlots().find((x: any) => x.getSlotId().getId() == slot + "_0");
        if (slotAdd == undefined) {
          googletag.defineSlot(slot, [[728, 90], [300, 250]], id).addService(googletag.pubads());
          googletag.pubads().enableSingleRequest();
          googletag.enableServices();
          googletag.display(id);
        } else {
          googletag.pubads().refresh([slotAdd]);
        }
      });
    }, 100);
  }

  /**
 * Funcion que obtiene y guarda en el sessionstorage los parametros que contengan la utm en su llave
 */
  getUtmParams(setUrl: boolean = true) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.utmParams = {};
        let params = Object.fromEntries((new URLSearchParams(window.location.search) as any).entries());
        for (const key in params) {
          if (key.toLowerCase().includes('utm')) {
            this.utmParams[key] = params[key];
            this.haveUtm = true;
          }
        }
        if (Object.keys(this.utmParams).length !== 0 && this.haveUtm) {
          sessionStorage.setItem('utmParams', JSON.stringify(this.utmParams));
        } else {
          let sessionUtm = JSON.parse(sessionStorage.getItem('utmParams') as string);
          if (sessionUtm != null) {
            this.utmParams = sessionUtm;
            this.haveUtm = true;
            if (this.utmParams != null && setUrl) {
              // setTimeout(() => {
              this.setParams(this.utmParams);
              // }, 100)
            }
          }
        }
      }
    });

  }

  /**
   * Fucnion que toma los parametros de UTM y los devuelve en forma de query string
   * @returns query string UTM
   */
  createUTM() {
    this.getUtmParams(false);
    let params = JSON.parse(sessionStorage.getItem('utmParams') as string);
    let utm = ''
    if (params != null) {
      for (const key in params) {
        utm = utm + key + '=' + params[key] + '&';
      }
      utm = utm.substring(0, utm.length - 1);
    }
    return utm;
  }

  /**
   * Funcion que valida una url y sus parametros para generar una url con query string
   * @param url url al cual se quiere concatenar un query string
   * @param params parametros en formato query string para concatenar a la url
   * @returns url con un query string
   */
  urlWithParams(url: string, params: string) {
    if (params != '') {
      if (url.includes('?')) {
        return url + '&' + params;
      } else {
        return url + '?' + params;
      }
    } else {
      return url;
    }
  }

  /**
   * Funcion para agregar los parametros utm en la url actual
   * @param params Parametros utm para agregar en la url
   */
  setParams(params: Params) {
    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: params,
    //   queryParamsHandling: 'merge', // remove to replace all query params by provided
    // }
    // );
    let path = window.location.pathname;
    let hash = window.location.hash;
    let paramsUrl = new URLSearchParams(window.location.search);
    let newParams = new URLSearchParams(params);
    // window.history.replaceState({}, '', `${path}?${paramsUrl.toString()+'&'+newParams.toString()}${hash}`);
    window.history.pushState(null, '', `${path}?${paramsUrl.toString() + '&' + newParams.toString()}${hash}`);
  }

  /**
   * Funcion que valida el producto para generar la url al cual redireccionar con la utm si existe
   * @param product producto al cual se quiere redireccionar
   * @returns url del producto con la utm si existe
   */
  getUrlProduct(product: string) {
    let urlsProduct: any = {
      'midc': this.urlWithParams(environment.validLogin_midc, this.createUTM()),
      'bc': this.urlWithParams(environment.validLogin_bc, this.createUTM()),
      'pntd': this.urlWithParams(environment.validLogin_pntd, this.createUTM()),
      'vrf': this.urlWithParams(environment.validLogin_vrf, this.createUTM())
      // 'os' : environment.validLogin_vrf + '?' + this.createUTM()
      // 'midc' : environment.validLogin_midc + '?' + this.createUTM(),
    }
    return (urlsProduct[product] != undefined ? urlsProduct[product] : urlsProduct['midc']);
  }
}
