<div [ngClass]="removeDatina()"></div>
<div class="formulario-chatlayerUsuarios" style="display: none">
    <form action="" id="formularioChatLayer" method="post" class="formularioChatLayer" autocomplete="off">
        <div class="titulo">
            <img class="formulario__imgChatLayer" src="./../../../assets/img/chat-datina/Datina_Personas.png" alt="" />
            <h1 class="formulario__tituloChatLayer">
                &iexcl;Bienvenido/a! Soy Datina, <br />
                tu asistente virtual
            </h1>
            <p class="formulario__subtituloChatLayer">
                Para iniciar la conversaci&oacute;n, por favor diligencia los siguientes datos:
            </p>
            <div class="botonCerrar" onclick="esconderFormularioDatosChatlayer()">
                X
            </div>
        </div>
        <div class="contenido">
            <input type="text" id="nombreChatLayer" onkeyup="validanteDatosUserChatLayer(this)"
                class="formulario__inputChatLayer" />
            <label for="nombre" id="nombreChatLayerLabel" class="formulario__labelChatLayer">Nombres</label>
            <input type="email" id="correoChatLayer" onkeyup="validanteDatosUserChatLayer(this)"
                class="formulario__inputChatLayer" />
            <label for="correo" id="correoChatLayerLabel" class="formulario__labelChatLayer">Correo</label>

            <br />
            <select id="tipoDocumentoChatLayer" class="formulario__inputChatLayerCheckbox">
                <option value="Cédula de Ciudadanía NUIP">C&eacute;dula de ciudadan&iacute;a y NUIP</option>
                <option value="NIT">NIT</option>
                <option value="Persona jurídica del extranjero">Persona jur&iacute;dica del extranjero</option>
                <option value="Cédula de Extranjería">C&eacute;dula de Extranjer&iacute;a</option>
                <option value="Pasaporte">Pasaporte</option>
                <option value="Camé Diplomatico">Cam&eacute; diplom&aacute;tico</option>
                <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                <option value="DNI">DNI</option>
                <option value="PEP">PEP</option>
            </select>

            <label for="tipoDocumento" id="tipoDocumentoChatLayerLabel"
                class="formulario__labelChatLayer fijarChatLayer2">Tipo de documento</label>
            <input type="number" id="documentoChatLayer" onkeyup="validanteDatosUserChatLayer(this)"
                class="formulario__inputChatLayer" />
            <label for="documento" id="documentoChatLayerLabel" class="formulario__labelChatLayer">Documento</label>
            <input type="checkbox" id="tratamientoDatosChatLayer"
                onchange="validarPoliticaTratamientoDatosChatlayer(this)" /><label for="tratamientoDatosChatLayer"
                id="tratamientoDatosChatLayerLabel">&iquest;Aceptas la pol&iacute;tica de
                <a href="https://www.midatacredito.com/politicas-privacidad" target="_blank">Tratamiento de datos
                    personales</a>
                y los <a href="https://www.midatacredito.com/terminos-condiciones" target="_blank">T&eacute;rminos y
                    condiciones de Midatacr&eacute;dito
                </a>?</label>
            <br /><br />
            <input type="button" id="submitFormChatLayer" class="formulario__submitChatLayer deshabilitadoChatLayer"
                value="Enviar formulario" disabled="true" onclick="enviarDatosUserChatLayer()" />
        </div>
    </form>

    <div id="loadingChatLayer" style="display: none">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="
          margin: auto;
          background: rgb(255, 255, 255);
          display: block;
          shape-rendering: auto;
        " width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <g transform="rotate(0 50 50)">
                <rect x="46.5" y="21.5" rx="1.8" ry="1.8" width="7" height="15" fill="#632678">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9s"
                        repeatCount="indefinite"></animate>
                </rect>
            </g>
            <g transform="rotate(36 50 50)">
                <rect x="46.5" y="21.5" rx="1.8" ry="1.8" width="7" height="15" fill="#632678">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8s"
                        repeatCount="indefinite"></animate>
                </rect>
            </g>
            <g transform="rotate(72 50 50)">
                <rect x="46.5" y="21.5" rx="1.8" ry="1.8" width="7" height="15" fill="#632678">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.7s"
                        repeatCount="indefinite"></animate>
                </rect>
            </g>
            <g transform="rotate(108 50 50)">
                <rect x="46.5" y="21.5" rx="1.8" ry="1.8" width="7" height="15" fill="#632678">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6s"
                        repeatCount="indefinite"></animate>
                </rect>
            </g>
            <g transform="rotate(144 50 50)">
                <rect x="46.5" y="21.5" rx="1.8" ry="1.8" width="7" height="15" fill="#632678">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s"
                        repeatCount="indefinite"></animate>
                </rect>
            </g>
            <g transform="rotate(180 50 50)">
                <rect x="46.5" y="21.5" rx="1.8" ry="1.8" width="7" height="15" fill="#632678">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4s"
                        repeatCount="indefinite"></animate>
                </rect>
            </g>
            <g transform="rotate(216 50 50)">
                <rect x="46.5" y="21.5" rx="1.8" ry="1.8" width="7" height="15" fill="#632678">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3s"
                        repeatCount="indefinite"></animate>
                </rect>
            </g>
            <g transform="rotate(252 50 50)">
                <rect x="46.5" y="21.5" rx="1.8" ry="1.8" width="7" height="15" fill="#632678">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.2s"
                        repeatCount="indefinite"></animate>
                </rect>
            </g>
            <g transform="rotate(288 50 50)">
                <rect x="46.5" y="21.5" rx="1.8" ry="1.8" width="7" height="15" fill="#632678">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.1s"
                        repeatCount="indefinite"></animate>
                </rect>
            </g>
            <g transform="rotate(324 50 50)">
                <rect x="46.5" y="21.5" rx="1.8" ry="1.8" width="7" height="15" fill="#632678">
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s"
                        repeatCount="indefinite"></animate>
                </rect>
            </g>
        </svg>
    </div>

    <div id="enviadoChatLayer" style="display: none">
        Formulario enviado con &eacute;xito!
    </div>
</div>
<div class="padreButtonFormulario">
    <div class="chatlayer-chatbox-buttonFormulario" onclick="mostrarFormularioDatosChatlayer()">
        <img src="./../../../assets/img/chat-datina/Datina_Personas.png" style="width: 85%; height: 85%" />
    </div>
</div>
<div id="myModalAgrandarImagenDatina" class="modalAgrandarImagenDatina">
    <span class="closeAgrandarImagenDatina">&times;</span>
    <img class="modal-contentAgrandarImagenDatina" id="img01AgrandarImagenDatina">
</div>
<input type="hidden" id="requestChatLayer" value="QgBlAGEAcgBlAHIAIABlAHkASgBoAGIARwBjAGkATwBpAEoASQBVAHoASQAxAE4AaQBJAHMASQBuAFIANQBjAEMASQA2AEkAawBwAFgAVgBDAEoAOQAuAGUAeQBKAHYAYwBtAGQAaABiAG0AbAA2AFkAWABSAHAAYgAyADUASgBaAEMASQA2AEkAbQBNADIATgBEAEoAagBPAEcAVQAzAEwAVABBADIATwBUAEEAdABOAEQATgBsAE0AeQAxAGkATQB6AFIAaABMAFQAUQB5AFkAagBkAGwAWgBqAE0AeABaAEQAVQAyAFoAQwBJAHMASQBuAFYAegBaAFgASQBpAE8AbgBzAGkAWgBXADEAaABhAFcAdwBpAE8AaQBKAHEAZABXAHgAcABZAFcANAB1AGMAMgBGAHUAZABHAGwAaABaADIAOAB1AFoAWABoADAAUQBHAFYANABjAEcAVgB5AGEAVwBGAHUATABtAE4AdgBiAFMASQBzAEkAbgBOADEAWQBpAEkANgBJAG0AVQAxAFoAVABKAGkATwBUAFUAMwBMAFQAWQB4AE0ARABRAHQATgBEAEkANABPAEMAMAA0AE4ARwBZAHcATABUAFEAMQBOAHoAWgBoAE8AVABJAHkATgBXAE4AbABOAEMASQBzAEkAbgBKAHYAYgBHAFYAegBJAGoAcABiAEkAbQBSAGwAWgBtAEYAMQBiAEgAUQB0AGMAbQA5AHMAWgBYAE0AdABZADIAaABoAGQARwB4AGgAZQBXAFYAeQBJAGkAdwBpAGIAMgBaAG0AYgBHAGwAdQBaAFYAOQBoAFkAMgBOAGwAYwAzAE0AaQBMAEMASgAxAGIAVwBGAGYAWQBYAFYAMABhAEcAOQB5AGEAWABwAGgAZABHAGwAdgBiAGkASgBkAGYAUwB3AGkAWQAzAEoAbABZAFgAUgBsAFoARQBGADAASQBqAG8AeABOAGoATQB3AE0AegBNAHgATwBEAE0AdwBPAFQAVQAyAEwAQwBKAHAAWQBYAFEAaQBPAGoARQAyAE0AegBBAHoATQB6AEUANABNAHoAQgA5AC4AeABhAHgAbABnAE4ARABhAEsANgBpAGQAUAA4AG0AWgBtAGEAcwB5AGEAcgBLAFcARwA2AE0ARQBtAHYAVQBaAGsAbwBzADgAYQBEAFgAYgBTAEcAYwA=">