import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataLayerService } from 'src/app/services/data-layer.service';
import { environment } from '../../../environments/environment';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { SesionService } from 'src/app/services/sesion.service';
import { BrokerService } from 'src/app/services/broker.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  logo: any;
  class: any;
  currentYear: any;
  paramUrl: any;
  public user_type: string = '';
  public checkDivMIDC = false;

  constructor(public content: ProductService, public activeroute: ActivatedRoute, private dlService: DataLayerService, public utilities: UtilitiesService,
              private sesion: SesionService, private broker: BrokerService, private router: Router ) {
    const date = new Date();
    this.currentYear = date.getFullYear();
    this.activeroute.queryParams.subscribe( (params): any => {
      // console.log('params', params);
      this.paramUrl = params['product'];
    });
  }

  ngOnInit() {
    this.showOrhideBanner();
  }

  /**
   * Esta función tiene de objetivo Mostrar u ocultar banner en la página de perfil de usuario.
   * Se muestra para usuarios free
   * Se oculta para usuarios private
   */
  showOrhideBanner(){
    const perfil_route = this.router.url.split('?')[0];
    if (perfil_route === '/private/perfil'){
      let id_sesion = this.sesion.getCookie();
      this.broker.datosUsuario(id_sesion).subscribe({
        next: (result: any) => {
          this.user_type = result.params[1].value;
        },
        error: (error: any) => {
          console.error('Error al obtener el tipo de usuario')
        }
      });
    }
  }

  /**
   * Esta función envia un data layer de GA4 "clic_footer_menu" por cada opción que el usuario elija.
   * Además redirige a la opción seleccionada.
   * @param nombre_boton Variable en donde se almacena la opción seleccionada por el usuario.
   * @param url Url destino donde se realiza la redirección.
   */
  tracking_datalayer( nombre_boton:string, url: string | any ){
    this.dlService.dl_eventoGA4_menu( 'clic_footer_menu', nombre_boton );
    window.open(url);
  }

  checkBannerMIDC(){
		if (!this.checkDivMIDC){
			this.utilities.addPubliById(environment.slotsBanners.loginMIDC,'div-gpt-ad-1694399862191-0');
			this.checkDivMIDC = true;
		}
		return ''
	}

}
