import { NgModule } from '@angular/core';

// Imports
import { RecaptchaModule, RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { AppConfigModule } from './app-config.module';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Components
import { AppComponent } from './app.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ModalComponent } from './helpers/modal/modal.component';
import { LoadingComponent } from './helpers/loading/loading.component';

// Providers
import { CanActiveViaAuthGuard } from './services/auth-guard.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';

// Material
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { ChatDatinaComponent } from './shared/chat-datina/chat-datina.component';


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    ModalComponent,
    LoadingComponent,
    ChatDatinaComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AppConfigModule,
    RecaptchaModule,
    RecaptchaV3Module,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    BrowserAnimationsModule
  ],
  providers: [
    ModalComponent,
    LoadingComponent,
    CookieService,
    CanActiveViaAuthGuard,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Lf-c0gaAAAAAOMDQJ9e35rrwTb8FeQ0t3rl3rTn' },
  ],
  entryComponents: [
    ModalComponent,
    LoadingComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
