<div class="modal p-0" tabindex="-1" role="dialog" id="loading">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content m-auto">
      <div class="modal-body p-0">
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button> -->
        <div class="container-fluid">
            <div class="row text-center">
                <div class="col-md-12 ml-auto">
                  <div id="loadingAnimation" class="animation m-auto"></div>
                </div>
                <div class="col-md-7 mx-auto ml-auto title">
                  {{ content.titulo }}
                </div>
                <div class="col-md-7  mx-auto ml-auto text">
                  {{ content.texto }}
                </div>
                <div class="col-md-12 ml-auto mt-4">
                  <img src="assets/images/loading/loader.gif" alt="cargando">
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>