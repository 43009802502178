import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
// import { SeguridadComponent } from './pages/seguridad/seguridad.component';


// Auth Guard
import { CanActiveViaAuthGuard } from './services/auth-guard.service';
// import { QuestionComponent } from './pages/question/question.component';
// import { VerificaComponent } from './pages/seguridad/verifica/verifica.component';
// import { OtpComponent } from './pages/seguridad/otp/otp.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },

  // Registro
  {
    path: 'registro',
    loadChildren: () => import('./pages/registro/registro.module').then(m => m.RegistroModule),
    pathMatch: 'full',
    canActivate: [CanActiveViaAuthGuard]
  },
  {
    path: 'registro/validar',
    loadChildren: () => import('./pages/registro/evidente/evidenteregistro.module').then(m=>m.EvidenteRegistroModule),
    pathMatch: 'full',
    canActivate: [CanActiveViaAuthGuard]
  },

  // Acceder
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    pathMatch: 'full',
    canActivate: [CanActiveViaAuthGuard]
  },

  // Recuperar contraseña
  {
    path: 'recovery',
    loadChildren: () => import('./pages/password/password.module').then(m => m.PasswordModule),
    pathMatch: 'full',
    canActivate: [CanActiveViaAuthGuard]
  },
  {
    path: 'recovery/recover',
    loadChildren: () => import('./pages/password/passrecovery/passrecovery.module').then(m => m.PassrecoveryModule),
    pathMatch: 'full',
    canActivate: [CanActiveViaAuthGuard]
  },
  {
    path: 'recovery/mail',
    loadChildren: () => import('./pages/password/passmail/passmail.module').then(m => m.PassmailModule),
    pathMatch: 'full',
    canActivate: [CanActiveViaAuthGuard]
  },
  {
    path: 'recovery/validation',
    loadChildren: ()=>import('./pages/password/passvalidation/passvalidation.module').then(m => m.PassvalidationModule),
    pathMatch: 'full',
    canActivate: [CanActiveViaAuthGuard]
  },

  // Oneshot
  {
    path: 'oneshot',
    //loadChildren: './pages/undia/undia.module#UndiaModule',
    loadChildren: () => import('./pages/oneshot/oneshot.module').then(m => m.OneshotModule),
    pathMatch: 'full',
    canActivate: [CanActiveViaAuthGuard]
  },
  {
    path: 'oneshot/payments',
    loadChildren: () =>import('./pages/oneshot/payments/payments.module').then(m => m.PaymentsModule),
    pathMatch: 'full',
    canActivate: [CanActiveViaAuthGuard]
  },
  {
    path: 'oneshot/confirmacion',
    loadChildren: ()=>import('./pages/oneshot/osresponse/osresponse.module').then(m => m.OsresponseModule),
    pathMatch: 'full',
    canActivate: [CanActiveViaAuthGuard]
  },
  {
    path: 'oneshot/validar',
    loadChildren: ()=>import('./pages/oneshot/evidente/oneshotevidente.module').then(m=>m.OneShotEvidenteModule),
    pathMatch: 'full',
    canActivate: [CanActiveViaAuthGuard]
  },
  {
    path: 'terminos-condiciones',
    loadChildren: ()=>import('./pages/terms/terms.module').then(m=>m.TermsModule),
    pathMatch: 'full',
    canActivate: [CanActiveViaAuthGuard]
  },
  // Error
  {
    path: 'error',
    loadChildren: ()=>import('./pages/error/error.module').then(m=>m.ErrorModule),
    pathMatch: 'full',
    canActivate: [CanActiveViaAuthGuard]
  },

  // Otros
  // { path: 'seguridad', component: SeguridadComponent, pathMatch: 'full', canActivate: [ CanActiveViaAuthGuard ]},
  // { path: 'seguridad/verifica', component: VerificaComponent, pathMatch: 'full', canActivate: [ CanActiveViaAuthGuard ]},
  // { path: 'seguridad/otp', component: OtpComponent, pathMatch: 'full', canActivate: [ CanActiveViaAuthGuard ]},
  // { path: 'pregunta', component: QuestionComponent, pathMatch: 'full', canActivate: [ CanActiveViaAuthGuard ]},


  // Private
  {
    path: 'private',
    loadChildren: ()=>import('./private/private.module').then(m => m.PrivateModule),
    canActivate: [CanActiveViaAuthGuard]
  },

  // page not found
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
