import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  imgLateral: any;
  titulo: any;
  textoHTML: any;
  textBoton: any;
  enlace: any;
  enlaceBoton: any;
  typeModal: any;
  enlace2texto: any;
  closeButton: any;

  constructor() { }

  close() {
    this.imgLateral = '';
    this.titulo = '';
    this.textoHTML = '';
    this.enlace = false;
    this.enlaceBoton = '';
    this.textBoton = '';
    this.typeModal = '';
    $('#modal').modal('hide');
  }

  // tslint:disable-next-line: max-line-length
  open( title: string, textHTML: string, imagen: string, enlace: boolean, enlaceBoton: string, textBoton: string, typeModal: any, enlace2texto: any = null, closeButton: any = null) {
    this.imgLateral = imagen;
    this.titulo = title;
    this.textoHTML = textHTML;
    this.enlace = enlace;
    this.enlaceBoton = enlaceBoton;
    this.textBoton = textBoton;
    this.typeModal = typeModal;
    this.enlace2texto = enlace2texto;
    $('#modal').modal({backdrop: 'static', keyboard: false});
  }
}
